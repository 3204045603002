<template>
    <MustervorlagenComp ></MustervorlagenComp>
</template>

<script>
    import MustervorlagenComp from '../components/Mustervorlagen.vue'
    export default {
        name: "Mustervorlagen",
        components: {
            MustervorlagenComp,
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToNormal');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>

<style scoped>

</style>